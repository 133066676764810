/* eslint-disable @backstage/no-relative-monorepo-imports */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Content, Header, InfoCard, Page } from '@backstage/core-components';

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';

import {
  APPROVER_EMAILS,
  DEPLOY_ROLE_FORM_DESCRIPTION,
  DEPLOY_ROLE_FORM_NAME,
} from '../../../../../packages/app/src/common/constants';

import { Account, State } from '../../../../../packages/app/src/store/types';

import {
  setApplicationAccounts,
  setGithubRepo,
} from '../../../../../packages/app/src/store/actions';
import { addNotifications } from '../../../../../packages/app/src/store/notifications';
import {
  DeployRoleCreationWorkflowRequest,
  Request,
  SDLC_STAGES,
  fetchAccountData,
  fetchGithubReposByOrg,
  triggerRequest,
} from '../../internal';
import { AsynchronousAutoComplete, BreadBoardAutoComplete } from '../common';
import { BreadBoardButtonGroup } from '../common/BreadBoardButtonGroup';

export const DeployRoleForm = () => {
  const dispatch = useDispatch();

  const accessToken: string = useSelector((state: State) => state.accessToken);
  const email = useSelector((state: State) => state.email);

  useEffect(() => {
    if (accessToken) {
      fetchAccountData(accessToken).then(accountData => {
        dispatch(setApplicationAccounts(accountData.applicationAccounts));
      });
    }
  }, [dispatch, accessToken]);

  const applicationAccounts = useSelector(
    (state: State) => state.applicationAccounts,
  );
  const githubRepos = useSelector((state: State) => state.githubRepos);

  const [githubOrgValue, setGithubOrgValue] = useState<string>('');
  const [githubRepoDisabled, setGithubRepoDisabled] = useState<boolean>(true);
  const [approver, setApprover] = useState<string>('');
  const [repoName, setRepoName] = useState<string>('');
  const [deployRoleName, setDeployRoleName] = useState<string>('');
  const [envValue, setEnvValue] = useState<string>(SDLC_STAGES.NON_PROD);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedAccountDetail, setSelectedAccountDetail] = useState<Account>({
    accountEmail: '',
    accountId: '',
    accountName: '',
    accountOwner: '',
    accountType: '',
    accountState: '',
    accountStage: '',
    requestId: '',
  });

  const githubOrgOptions = {
    Option1: {
      value: 'adi-innersource',
      label: 'adi-innersource',
      isVisible: true,
    },
    Option2: {
      value: 'adi-partners',
      label: 'adi-partners',
      isVisible: true,
    },
  };

  const onGithubOrgChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGithubOrgValue(event.target.value);
    setGithubRepoDisabled(false);
  };

  useEffect(() => {
    if (selectedAccountDetail.accountName.includes(SDLC_STAGES.PROD)) {
      setEnvValue(SDLC_STAGES.PROD);
    } else if (selectedAccountDetail.accountName.includes(SDLC_STAGES.DEV)) {
      setEnvValue(SDLC_STAGES.DEV);
    } else if (selectedAccountDetail.accountName.includes(SDLC_STAGES.QA)) {
      setEnvValue(SDLC_STAGES.QA);
    } else if (selectedAccountDetail.accountName.includes(SDLC_STAGES.STAGE)) {
      setEnvValue(SDLC_STAGES.STAGE);
    } else if (selectedAccountDetail.accountName.includes(SDLC_STAGES.UAT)) {
      setEnvValue(SDLC_STAGES.UAT);
    } else {
      setEnvValue(SDLC_STAGES.NON_PROD);
    }
    setDeployRoleName(
      repoName
        ? `spx-ops-${repoName
            .toLowerCase()
            .replace(/\s+/g, '-')}-deploy-${envValue.toLowerCase()}`
        : '',
    );
  }, [envValue, repoName, selectedAccountDetail.accountName]);

  useEffect(() => {
    if (githubOrgValue && accessToken) {
      setIsLoading(true);
      fetchGithubReposByOrg(accessToken, githubOrgValue)
        .then(response => {
          dispatch(setGithubRepo(response));
          setGithubRepoDisabled(false);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [githubOrgValue, accessToken, dispatch]);

  const handleReset = () => {
    setSelectedAccountDetail({
      accountEmail: '',
      accountId: '',
      accountName: '',
      accountOwner: '',
      accountType: '',
      accountState: '',
      accountStage: '',
      requestId: '',
    });
    setApprover('');
    setRepoName('');
    setIsLoading(false);
    setDeployRoleName('');
    setGithubOrgValue('');
    setGithubRepoDisabled(true);
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    // const requestMessage = `Request: Create a deploy role in '${selectedAccountDetail.accountName}' account for '${repoName}' repository.`;
    const requestMessage = `Request: Create a AWS-GitHub Deploy Role<br />
    Requester: ${email}<br />
    AWS Account Name: ${selectedAccountDetail.accountName}<br />
    GitHub Repository: ${repoName}<br />
    Deploy Role Name: ${deployRoleName}<br />
    `;

    const requestContent: DeployRoleCreationWorkflowRequest = {
      requester: email,
      projectName: repoName,
      accountId: selectedAccountDetail.accountId,
      repoName: repoName,
      githubOrganisation: githubOrgValue,
    };

    const request: Request = {
      requestType: DEPLOY_ROLE_FORM_NAME,
      description: requestMessage,
      approver: approver,
      requester: email,
      content: requestContent,
    };

    const response = await triggerRequest(request, accessToken);
    const requestId = response.data.requestId;

    addNotifications(
      DEPLOY_ROLE_FORM_NAME,
      `Request sent for approval to approver: '${approver}'.
      You can track the request '${requestId}' in 'Submissions' Tab in Self-Service.`,
      'info',
    );

    setIsLoading(false);

    handleReset();
  };

  const submitCheck = () => {
    const canSubmit =
      isLoading || !selectedAccountDetail || !repoName || !approver;
    return canSubmit;
  };

  return (
    <Page themeId="tool">
      <Header
        title={DEPLOY_ROLE_FORM_NAME}
        subtitle={DEPLOY_ROLE_FORM_DESCRIPTION}
      />
      <Content>
        <Grid container spacing={3} direction="column">
          <Grid item xs={12} md={6}>
            <InfoCard>
              <FormControl variant="standard" fullWidth margin="dense">
                <FormLabel id="github-org-radio-button">
                  GitHub Organisation
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="github-org-radio-button"
                  name="radio-buttons-group"
                  onChange={onGithubOrgChange}
                  value={githubOrgValue}
                >
                  {githubOrgOptions.Option1.isVisible && (
                    <FormControlLabel
                      value={githubOrgOptions.Option1.value}
                      control={<Radio />}
                      label={githubOrgOptions.Option1.label}
                    />
                  )}
                  {githubOrgOptions.Option2.isVisible && (
                    <FormControlLabel
                      value={githubOrgOptions.Option2.value}
                      control={<Radio />}
                      label={githubOrgOptions.Option2.label}
                    />
                  )}
                </RadioGroup>
                <br />
                <AsynchronousAutoComplete
                  value={selectedAccountDetail}
                  options={applicationAccounts}
                  getOptionLabel={option => option.accountName}
                  groupBy={option => option.accountName[0]}
                  label="Account Name"
                  id="account-details"
                  onChange={(_event: any, newValue: any) => {
                    setSelectedAccountDetail(newValue);
                  }}
                />
                <FormHelperText id="Account Name">
                  Select the AWS Account to create the role on
                </FormHelperText>
                <br />

                <AsynchronousAutoComplete
                  value={repoName}
                  options={githubRepos}
                  getOptionLabel={option => option}
                  groupBy={option => option[0]}
                  label="GitHub Repository"
                  id="repo-list"
                  onChange={(_event: any, newValue: any) => {
                    setRepoName(newValue);
                  }}
                  isTextFieldDisable={githubRepoDisabled}
                />
                <FormHelperText id="RepoName">
                  Select a GitHub Repository from the list above to associate
                  the deploy role with
                </FormHelperText>
                <br />

                <TextField
                  disabled
                  label="Deploy Role Name"
                  variant="outlined"
                  placeholder="Deploy Role Name"
                  value={deployRoleName}
                />
                <FormHelperText id="deploy-role-name-helper-text">
                  The deploy role name will be a lowercased and hypenated
                  version of the github repository name selected above with a
                  prefix `spx-ops-` and postfix `-deploy-role-dev`
                  <br />
                  E.g. For "spx-ops-my-repository-deploy-role-dev".
                  <br />
                </FormHelperText>
                <br />

                <BreadBoardAutoComplete
                  id="approver"
                  options={APPROVER_EMAILS}
                  value={approver}
                  onChange={(_event: any, newValue: any) => {
                    setApprover(newValue);
                  }}
                  label="Approver's Email"
                />
                <FormHelperText id="approver-helper-text">
                  Please choose an approver who will authorise the creation of
                  the AWS/GitHub Deploy Role.
                </FormHelperText>
                <br />
              </FormControl>

              <BreadBoardButtonGroup
                handleSubmit={handleSubmit}
                handleReset={handleReset}
                submitCheck={submitCheck}
                isLoading={isLoading}
              />
            </InfoCard>
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};
