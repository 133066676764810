/* eslint-disable no-console */
import React, { useState } from 'react';

import { Table, TableColumn } from '@backstage/core-components';
import { Drawer } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Link from '@mui/material/Link';

import { RequestStatusResponse } from '../../../internal';
import { RequestDrawer } from './RequestDrawer';
import { RequestStatusChip } from './RequestStatusChip';

const useDrawerStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '50%',
      padding: theme.spacing(2.5),
    },
  }),
);

export const SubmissionsTable = ({
  data,
}: {
  data: RequestStatusResponse[];
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [requestDetails, setRequestDetails] = useState<
    Partial<RequestStatusResponse>
  >({});
  const classes = useDrawerStyles();

  const columns: TableColumn[] = [
    {
      title: 'Request Id',
      field: 'requestId',
      highlight: true,
      width: '10%',
      render: (row: Partial<RequestStatusResponse>) => (
        <Link
          component="button"
          onClick={() => {
            setIsDrawerOpen(true);
            setRequestDetails(row);
          }}
        >
          {row.requestId}
        </Link>
      ),
    },
    {
      title: 'Request Type',
      field: 'requestType',
      highlight: true,
      width: '20%',
    },
    {
      title: 'Status',
      field: 'requestStatus',
      highlight: true,
      width: '15%',
      render: (row: Partial<RequestStatusResponse>) => (
        <RequestStatusChip requestDetails={row} setActiveStep={() => {}} />
      ),
    },
    {
      title: 'Created',
      field: 'requestCreatedAt',
      highlight: true,
      width: '15%',
    },
  ];

  return (
    <>
      <Table
        title={`Submissions (${data.length})`}
        options={{ search: true, paging: true, pageSize: 10 }}
        columns={columns}
        data={data}
      />
      <Drawer
        classes={{
          paper: classes.paper,
        }}
        anchor="right"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <RequestDrawer requestDetails={requestDetails} />
      </Drawer>
    </>
  );
};
