import { configureStore } from '@reduxjs/toolkit';
import { State, CognitoAppClientDetails } from './types';
import {
  SET_EMAIL,
  SET_ACCESS_TOKEN,
  SET_LOGGED_IN_TIME_TOKEN,
  SET_APPLICATION_ACCOUNTS,
  SET_GITHUB_REPOS,
  SET_SSO_PERMISSION_SETS_USERS_ACTION,
  SET_CUSTOM_SCOPES,
  SET_APP_CLIENTS,
  SET_APP_CLIENT_DETAILS,
  SET_GROUP_VALUES,
} from '../common/constants';

const initialState: State = {
  email: '',
  accessToken: '',
  loggedInTime: '',
  githubRepos: [],
  applicationAccounts: [],
  ssoPermissionSetsUsers: [],
  customScopes: [],
  groupValues: [],
  appClients: {},
  appClientDetails: {} as CognitoAppClientDetails,
};

const reducer = (state = initialState, action: any): State => {
  switch (action.type) {
    case SET_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload,
      };
    case SET_LOGGED_IN_TIME_TOKEN:
      return {
        ...state,
        loggedInTime: action.payload,
      };
    case SET_APPLICATION_ACCOUNTS:
      return {
        ...state,
        applicationAccounts: action.payload,
      };
    case SET_GITHUB_REPOS:
      return {
        ...state,
        githubRepos: action.payload,
      };
    case SET_SSO_PERMISSION_SETS_USERS_ACTION:
      return {
        ...state,
        ssoPermissionSetsUsers: action.payload,
      };
    case SET_CUSTOM_SCOPES:
      return {
        ...state,
        customScopes: action.payload,
      };
    case SET_GROUP_VALUES:
      return {
        ...state,
        groupValues: action.payload,
      };
    case SET_APP_CLIENTS:
      return {
        ...state,
        appClients: action.payload,
      };
    case SET_APP_CLIENT_DETAILS:
      return {
        ...state,
        appClientDetails: action.payload,
      };
    default:
      return state;
  }
};

const store = configureStore({
  reducer,
});

export default store;
