/* eslint-disable @backstage/no-relative-monorepo-imports */
import React from 'react';
import { Header, InfoCard } from '@backstage/core-components';
import {
  Grid,
  Typography,
  Box,
  Stepper,
  Step,
  StepLabel,
} from '@material-ui/core';
import {
  RequestStatusResponse,
  ANVILOPS_REQUEST_STATES_LABEL,
  ANVILOPS_REQUEST_STATES,
  ANVILOPS_REQUEST_STEPS_NUMBER,
} from '../../../internal';
import { RequestStatusChip } from './RequestStatusChip';

interface RequestDrawerProps {
  requestDetails: Partial<RequestStatusResponse>;
}

const USER_LEVEL_ANVILOPS_REQUEST_STEPS: string[] = [
  ANVILOPS_REQUEST_STATES_LABEL.CREATED,
  ANVILOPS_REQUEST_STATES_LABEL.WAITING_APPROVAL,
  `${ANVILOPS_REQUEST_STATES_LABEL.APPROVED}/${ANVILOPS_REQUEST_STATES_LABEL.REJECTED}`,
  ANVILOPS_REQUEST_STATES_LABEL.IN_PROGRESS,
  ANVILOPS_REQUEST_STATES_LABEL.COMPLETED,
];

export const RequestDrawer: React.FC<RequestDrawerProps> = ({
  requestDetails,
}) => {
  const [activeStep, setActiveStep] = React.useState(0);

  const failedConditionForRequestStatus =
    requestDetails.requestStatus === ANVILOPS_REQUEST_STATES.WORKFLOW_FAILED ||
    requestDetails.requestStatus === ANVILOPS_REQUEST_STATES.REQUEST_FAILED ||
    requestDetails.requestStatus ===
      ANVILOPS_REQUEST_STATES.REQUEST_QUEUEING_FAILED ||
    requestDetails.requestStatus ===
      ANVILOPS_REQUEST_STATES.ACCOUNT_CUSTOMIZATION_FAILED ||
    requestDetails.requestStatus ===
      ANVILOPS_REQUEST_STATES.ACCOUNT_EMAIL_NOT_AVAILABLE ||
    requestDetails.requestStatus ===
      ANVILOPS_REQUEST_STATES.WORKFLOW_TRIGGER_FAILED;

  const isRequestRejected = (step: number) => {
    return (
      requestDetails.requestStatus === ANVILOPS_REQUEST_STATES.REJECTED &&
      step === ANVILOPS_REQUEST_STEPS_NUMBER.APPROVED_REJECTED
    );
  };
  const isRequestApproved = (step: number) => {
    return (
      requestDetails.requestStatus !== ANVILOPS_REQUEST_STATES.REJECTED &&
      requestDetails.requestStatus !== ANVILOPS_REQUEST_STATES.PENDING &&
      step === ANVILOPS_REQUEST_STEPS_NUMBER.APPROVED_REJECTED
    );
  };
  const isRequestFailed = (step: number) => {
    return (
      failedConditionForRequestStatus &&
      step === ANVILOPS_REQUEST_STEPS_NUMBER.COMPLETED_FAILED
    );
  };
  const isRequestPending = (step: number) => {
    return (
      requestDetails.requestStatus === ANVILOPS_REQUEST_STATES.PENDING &&
      step === ANVILOPS_REQUEST_STEPS_NUMBER.APPROVED_REJECTED
    );
  };

  return (
    <>
      <Header
        title={`Request Id: ${requestDetails.requestId}`}
        subtitle={`Request Type: ${requestDetails.requestType}`}
      />
      <br />
      <Grid>
        <InfoCard>
          <Typography variant="h5">Request Information:</Typography>
          <Typography variant="body1">
            <div>
              {Object.entries(requestDetails.description ?? {}).map(
                ([key, value]) => (
                  <div key={key}>
                    {key}: {value}
                  </div>
                ),
              )}
            </div>
          </Typography>
          <br />
          <Typography variant="body1">
            Approver: {requestDetails.approver}
          </Typography>
          <br />
          <Typography variant="body1">
            Request Status:{' '}
            <RequestStatusChip
              requestDetails={requestDetails}
              setActiveStep={setActiveStep}
            />
          </Typography>
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep} orientation="horizontal">
              {USER_LEVEL_ANVILOPS_REQUEST_STEPS.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: {
                  optional?: React.ReactNode;
                  error?: boolean;
                } = {};
                let labelString = label;
                if (isRequestRejected(index)) {
                  labelProps.error = true;
                  labelString = ANVILOPS_REQUEST_STATES_LABEL.REJECTED;
                }
                if (isRequestApproved(index)) {
                  labelString = ANVILOPS_REQUEST_STATES_LABEL.APPROVED;
                }
                if (isRequestPending(index)) {
                  labelString = label;
                }
                if (isRequestFailed(index)) {
                  labelProps.error = true;
                  labelString = ANVILOPS_REQUEST_STATES_LABEL.FAILED;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{labelString}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Box>
        </InfoCard>
      </Grid>
    </>
  );
};
