/* eslint-disable @backstage/no-relative-monorepo-imports */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import {
  Content,
  Header,
  InfoCard,
  Link,
  Page,
} from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';

import {
  FormControl,
  FormHelperText,
  Grid,
  TextField,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../../../packages/app/src/store/types';
import {
  AWS_DEV_ACCOUNT_CREATION_FORM_NAME,
  AWS_DEV_ACCOUNT_CREATION_FORM_DESCRIPTION,
  APPROVER_EMAILS,
  AWS_DEV_ACCOUNT_CREATION_INPUTS_DOC_LINK,
  DEVELOPER_ACCOUNTS_OU,
} from '../../../../../packages/app/src/common/constants';
import {
  AsynchronousAutoComplete,
  BreadBoardAutoComplete,
  BreadBoardButtonGroup,
} from '../common';
import { addNotifications } from '../../../../../packages/app/src/store/notifications';
import {
  setGroupValues,
  setSSOPermissionSetsUsers,
} from '../../../../../packages/app/src/store/actions';
import {
  fetchGroupValuesList,
  fetchSSOPermissionSetsUsers,
  validateUserEmail,
  triggerRequest,
  SDLC_STAGES,
  Request,
  AWSAccountCreationRequest,
} from '../../internal';

export const DevAccountRequestForm = () => {
  const catalogApi = useApi(catalogApiRef);
  const dispatch = useDispatch();

  const email = useSelector((state: State) => state.email);
  const accessToken = useSelector((state: State) => state.accessToken);

  const [ownerValue, setOwnerValue] = useState<string>('');
  const [ownerValueValidation, setOwnerValueValidation] =
    useState<boolean>(false);

  const [description, setDescription] = useState<string>('');

  const [groupValue, setGroupValue] = useState<string>('');

  const [approver, setApprover] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (accessToken) {
      fetchSSOPermissionSetsUsers(accessToken).then(response => {
        dispatch(setSSOPermissionSetsUsers(response));
      });
    }
  }, [dispatch, accessToken]);

  const ssoPermissionSetsUsers = useSelector(
    (state: State) => state.ssoPermissionSetsUsers,
  );

  useEffect(() => {
    if (accessToken) {
      fetchGroupValuesList(accessToken)
        .then(response => {
          if (response) {
            dispatch(setGroupValues(response)); // Dispatch the action to update global state
          } else {
            console.error('Group values not found in response');
            dispatch(setGroupValues([])); // Handle no response case
          }
        })
        .catch(error => {
          console.error('Failed to fetch group values:', error);
          dispatch(setGroupValues([])); // Handle error case
        });
    }
  }, [dispatch, accessToken]);

  const groupValuesList = useSelector((state: State) => state.groupValues);

  const getUserDetails = async (emailID: string) => {
    const user_details = await catalogApi.getEntities({
      filter: {
        kind: 'User',
        'spec.profile.email': emailID,
      },
    });
    return user_details;
  };

  const generateDescriptionFromEmail = (enteredEmail: string) => {
    const [localPart] = enteredEmail.split('@');
    const nameParts = localPart.split('.');
    const capitalizedName = nameParts
      .map(part => part.charAt(0).toUpperCase() + part.slice(1))
      .join(' ');
    return `Development account assigned to ${capitalizedName}`;
  };

  const onOwnerChange = async (event: any) => {
    const ownerEmail = event.target.value.toLowerCase();
    setOwnerValue(ownerEmail);

    if (ownerEmail.length >= 1) {
      if (validateUserEmail(ownerEmail, ssoPermissionSetsUsers)) {
        setOwnerValueValidation(false);
        const userDetails = await getUserDetails(ownerEmail);
        const groupOptionArr = [];
        if (userDetails.items[0] && userDetails.items[0].relations) {
          for (const item of userDetails.items[0].relations) {
            const elements = item.targetRef.split('/');
            groupOptionArr.push(elements[elements.length - 1]);
          }
        }
        // Set the description based on the email
        setDescription(generateDescriptionFromEmail(ownerEmail));
      } else {
        setOwnerValueValidation(true);
      }
    } else {
      setOwnerValueValidation(false);
    }
  };

  const handleReset = () => {
    setOwnerValue('');
    setGroupValue('');
    setDescription('');
    setApprover('');
    setIsLoading(false);
    setOwnerValueValidation(false);
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    const requestMessage = `Request: Create an AWS Developer Account<br />
    Requester: ${email}<br />
    Account Owner: ${ownerValue}<br />
    Group: ${groupValue}<br />
    Description: ${description}
    `;

    const content: AWSAccountCreationRequest = {
      name: '',
      owner: ownerValue,
      costApprover: approver,
      description: description,
      email: '',
      environment: SDLC_STAGES.DEV.toLowerCase(),
      group: groupValue,
      ou: DEVELOPER_ACCOUNTS_OU,
      requester: email,
      otherAccountUsers: '',
    };

    const request: Request = {
      requestType: AWS_DEV_ACCOUNT_CREATION_FORM_NAME,
      description: requestMessage,
      approver: approver.toLowerCase(),
      requester: email,
      content: content,
    };

    const response = await triggerRequest(request, accessToken);
    const requestId = response.data.requestId;

    addNotifications(
      AWS_DEV_ACCOUNT_CREATION_FORM_NAME,
      `Request sent for approval to cost approver: '${approver.toLowerCase()}'.
      You can track the request '${requestId}' in 'Submissions' Tab in Self-Service`,
      'info',
    );

    setIsLoading(false);
    handleReset();
  };

  const submitCheck = () => {
    const canSubmit =
      isLoading ||
      !ownerValue ||
      !groupValue ||
      !approver ||
      !description ||
      ownerValueValidation;

    return canSubmit;
  };

  return (
    <Page themeId="service">
      <Header
        title={AWS_DEV_ACCOUNT_CREATION_FORM_NAME}
        subtitle={AWS_DEV_ACCOUNT_CREATION_FORM_DESCRIPTION}
      />
      <Content>
        <Grid container spacing={3} direction="column">
          <Grid item xs={12} md={6}>
            <InfoCard>
              <FormControl variant="standard" fullWidth margin="dense">
                <TextField
                  label="Account Owner (Email Id)"
                  variant="outlined"
                  value={ownerValue}
                  required
                  onChange={onOwnerChange}
                  error={ownerValueValidation}
                  helperText={
                    ownerValueValidation
                      ? 'Enter Valid Email with analog.com domain OR User not part of AWS SSO.'
                      : ''
                  }
                />
                <FormHelperText id="account-owner">
                  Please enter the account owner's email address, ensuring it
                  uses the @analog.com domain. Additionally, confirm that the
                  user is part of the AWS SSO Group. For more information please
                  see{' '}
                  <Link to={AWS_DEV_ACCOUNT_CREATION_INPUTS_DOC_LINK}>
                    here
                  </Link>
                </FormHelperText>
                <br />

                <AsynchronousAutoComplete
                  id="group"
                  options={groupValuesList ? [...groupValuesList].sort() : []}
                  groupBy={(option: any) => option[0]}
                  getOptionLabel={(option: any) => option}
                  value={groupValue}
                  onChange={(_event: any, newValue: any) => {
                    setGroupValue(newValue);
                  }}
                  label="Group"
                />
                <FormHelperText id="group">
                  Please select the group/project associated with this account,
                  for example, Anvil. For more information or what if my group
                  doesn't display on the list? please see{' '}
                  <Link to={AWS_DEV_ACCOUNT_CREATION_INPUTS_DOC_LINK}>
                    here
                  </Link>
                </FormHelperText>
                <br />

                <BreadBoardAutoComplete
                  id="cost-approver"
                  options={APPROVER_EMAILS}
                  value={approver}
                  onChange={(_event: any, newValue: any) => {
                    setApprover(newValue);
                  }}
                  label="Cost Approver's Email"
                />
                <FormHelperText id="cost-approver-helper-text">
                  Please choose an approver who will authorise the creation of
                  this account
                </FormHelperText>
                <br />

                <TextField
                  label="Description"
                  variant="outlined"
                  value={description}
                  disabled
                />
                <FormHelperText id="description">
                  The description is automatically generated based on the
                  account owner's email.
                </FormHelperText>
                <br />
              </FormControl>
              <BreadBoardButtonGroup
                handleSubmit={handleSubmit}
                handleReset={handleReset}
                submitCheck={submitCheck}
                isLoading={isLoading}
              />
            </InfoCard>
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};
