export interface AWSUserAccessChangeWorkflowRequest {
  requester: string;
  permission: string;
  userEmails: string;
  changeType: string;
  accountId: string;
}

export interface DomainCreationWorkflowRequest {
  requester: string;
  accountId: string;
  domainString: string;
  domain: string;
}

export interface DeployRoleCreationWorkflowRequest {
  requester: string;
  projectName: string;
  accountId: string;
  repoName: string;
  githubOrganisation: string;
}

export interface AWSAccountCreationRequest {
  requester: string;
  name: string;
  email: string;
  owner: string;
  costApprover: string;
  otherAccountUsers: string;
  group: string;
  environment: string;
  description: string;
  ou: string;
}

export interface CognitoApplicationClientCreationRequest {
  requester: string;
  environment: string;
  applicationName: string;
  callbackURLs: string[];
  identifier?: string;
  resourceServerScopes?: Scope[];
  logoutURLs?: string[];
  customScopes?: string[];
}

export interface CognitoResourceServerCreationRequest {
  requester: string;
  identifier: string;
  name: string;
  scopes: Scope[] | string;
}

export interface CognitoApplicationClientUpdateRequest {
  requester: string;
  clientId: string;
  applicationName: string;
  callbackURLs: string[];
  logoutURLs?: string[];
  customScopes?: string[];
  environment: string;
}

export interface Request {
  requester: string;
  approver: string;
  content:
    | AWSAccountCreationRequest
    | AWSUserAccessChangeWorkflowRequest
    | CognitoApplicationClientCreationRequest
    | CognitoApplicationClientUpdateRequest
    | CognitoResourceServerCreationRequest
    | DeployRoleCreationWorkflowRequest
    | DomainCreationWorkflowRequest;
  requestStatus?: ANVILOPS_REQUEST_STATES;
  requestType: string;
  description: string;
  requestCreatedAt?: string;
}

export interface RequestStatusResponse extends Request {
  requestId: string;
}

export interface UpdateRequestStatus {
  requestId: string;
  requestStatus: string;
  rejectionReason?: string;
}

export interface Scope {
  ScopeName: string;
  ScopeDescription: string;
}

export interface PermissionOptions {
  name: string;
  value: string;
  description: string;
}

export interface DomainOptions {
  domain: string;
  prod: string;
  nonProd: string;
}

export interface Environments {
  Option1: EnvironmentDetails;
  Option2: EnvironmentDetails;
  Option3: EnvironmentDetails;
  Option4: EnvironmentDetails;
  Option5: EnvironmentDetails;
}

interface EnvironmentDetails {
  label: string;
  value: string;
  isVisible: boolean;
}

export interface UpdateAccountStateRequest {
  accountEmail: string;
  accountStage: string;
  requestId: string;
  accountState: ACCOUNT_STATE;
}

export interface StationCardDetails {
  cardId: string;
  type: string;
  title: string;
  address: string;
  description: string;
  documentationAddress: string;
}

export interface CognitoAppClientDetails {
  ClientName: string;
  AllowedOAuthScopes: string[];
  CallbackURLs: string[];
  LogoutURLs: string[];
}

export enum ANVILOPS_REQUEST_STATES {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  COMPLETED = 'completed',
  REQUEST_FORWARDED = 'request_forwarded',
  REQUEST_FAILED = 'request_failed',
  REQUEST_QUEUED = 'request_queued',
  REQUEST_QUEUEING_FAILED = 'request_queueing_failed',
  ACCOUNT_EMAIL_SELECTED = 'account_email_selected',
  ACCOUNT_EMAIL_NOT_AVAILABLE = 'account_email_not_available',
  WORKFLOW_TRIGGERED = 'workflow_triggered',
  WORKFLOW_TRIGGER_FAILED = 'workflow_trigger_failed',
  WORKFLOW_FAILED = 'workflow_failed',
  CODEPIPELINE_TRIGGERED = 'codepipeline_triggered',
  ACCOUNT_CREATED = 'account_created',
  ACCOUNT_CUSTOMIZATION_STARTED = 'account_customization_started',
  ACCOUNT_CUSTOMIZATION_IN_PROGRESS = 'account_customization_in_progress',
  ACCOUNT_CUSTOMIZATION_FAILED = 'account_customization_failed',
}

export enum NOTIFICATION_TYPE {
  ADMIN = 'admin',
  NOTIFICATION = 'notification',
}

export enum NOTIFICATION_COLOR {
  SUCCESS = 'success',
  DANGER = 'danger',
}

export enum ACCOUNT_STATE {
  USED = 'used',
  IN_PROGRESS = 'in_progress',
  AVAILABLE = 'available',
}

export enum BUTTON_COLOR {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export enum ANVILOPS_REQUEST_STEPS_NUMBER {
  CREATED = 0,
  WAITING_APPROVAL = 1,
  APPROVED_REJECTED = 2,
  IN_PROGRESS = 3,
  COMPLETED_FAILED = 4,
}

export enum ANVILOPS_REQUEST_STATES_LABEL {
  CREATED = 'Created',
  WAITING_APPROVAL = 'Waiting for approval',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  IN_PROGRESS = 'In Progress',
  COMPLETED = 'Completed',
  FAILED = 'Failed',
}

export enum REQUEST_TYPE {
  REQUESTER = 'requester',
  APPROVER = 'approver',
}

export enum SDLC_STAGES {
  DEV = 'Dev',
  QA = 'QA',
  STAGE = 'Stage',
  UAT = 'UAT',
  PROD = 'Prod',
  NON_PROD = 'Non-Prod',
  TEST = 'Test',
}

export enum ACCOUNT_TYPES {
  DEVELOPER = 'developer',
  APPLICATION = 'application',
}
