/* eslint-disable no-console */
/* eslint-disable @backstage/no-relative-monorepo-imports */
import React, { useEffect, useState } from 'react';
import { Content, Header, InfoCard, Page } from '@backstage/core-components';

import {
  Grid,
  TextField,
  FormHelperText,
  FormControl,
} from '@material-ui/core';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import ButtonGroup from '@mui/material/ButtonGroup';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import {
  COGNITO_APPROVER_EMAILS,
  COGNITO_RESOURCE_SERVER_CREATION_FORM_NAME,
  COGNITO_RESOURCE_SERVER_CREATION_FORM_DESCRIPTION,
  APPLICATION_NAME_FOR_APP_CLIENT_MAX_LENGTH,
  APPLICATION_NAME_FOR_APP_CLIENT_MIN_LENGTH,
} from '../../../../../packages/app/src/common/constants';
import { useSelector } from 'react-redux';
import { State } from '../../../../../packages/app/src/store/types';
import { BreadBoardAutoComplete, BreadBoardButtonGroup } from '../common';
import { addNotifications } from '../../../../../packages/app/src/store/notifications';
import {
  formatScopes,
  validateScopeNameString,
  triggerRequest,
  Request,
  Scope,
  CognitoResourceServerCreationRequest,
  validateTitleCase,
} from '../../internal';

export const CognitoResourceServerCreationForm = () => {
  const accessToken = useSelector((state: State) => state.accessToken);
  const email = useSelector((state: State) => state.email);
  const [approver, setApprover] = useState<string>('');

  const [identifierNameValue, setIdentifierNameValue] = useState<string>('');
  const [resourceServerNameValue, setResourceServerNameValue] =
    useState<string>('');
  const [identifierNameValidation, setIdentifierNameValidation] =
    useState<boolean>(false);
  const [resourceServerNameValidation, setResourceNameValidation] =
    useState<boolean>(false);
  const [scopeNamesValidation, setScopeNamesValidation] = useState<boolean[]>([
    false,
  ]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [scopes, setScopes] = useState<Scope[]>([
    { ScopeName: '', ScopeDescription: '' },
  ]);

  useEffect(() => {
    setResourceServerNameValue(`${resourceServerNameValue}`);
  }, [resourceServerNameValue]);

  const handleInputChange = (index: number, event: any, key: keyof Scope) => {
    const newScopes = [...scopes];

    if (key === 'ScopeName') {
      const newScopeValidations = [...scopeNamesValidation];
      newScopeValidations[index] = !validateScopeNameString(event.target.value);
      setScopeNamesValidation(newScopeValidations);
    }

    newScopes[index][key] = event.target.value;
    setScopes(newScopes);
  };

  const handleAddInput = () => {
    setScopes([...scopes, { ScopeName: '', ScopeDescription: '' }]);
  };
  const handleRemoveInput = (index: number) => {
    const newScopes = [...scopes];
    newScopes.splice(index, 1);
    setScopes(newScopes);
  };

  const handleReset = () => {
    setIdentifierNameValue('');
    setResourceServerNameValue('');
    setScopes([
      {
        ScopeName: '',
        ScopeDescription: '',
      },
    ]);
    setIdentifierNameValidation(false);
    setResourceNameValidation(false);
    setIsLoading(false);
    setApprover('');
  };

  const onResourceNameChange = (event: any) => {
    setResourceNameValidation(
      !validateTitleCase(
        event.target.value,
        APPLICATION_NAME_FOR_APP_CLIENT_MIN_LENGTH,
        APPLICATION_NAME_FOR_APP_CLIENT_MAX_LENGTH,
      ),
    );
    setResourceServerNameValue(event.target.value);
    const lowerCaseHyphenatedResourceServerName = event.target.value
      .toLowerCase()
      .replace(/\s+/g, '-');
    setIdentifierNameValue(lowerCaseHyphenatedResourceServerName);
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    // For displaying the Scope Values
    const scopesRequestMessage = formatScopes(identifierNameValue, scopes);

    const requestMessage = `Request: Create Cognito Resource Server<br />
    Requester: ${email}<br />
    Resource Server Name: ${resourceServerNameValue}<br />
    Identifier Name: ${identifierNameValue}<br />
    Scope(s): <br/>
    ${scopesRequestMessage}
    `;

    const content: CognitoResourceServerCreationRequest = {
      requester: email,
      name: resourceServerNameValue,
      identifier: identifierNameValue,
      scopes: scopes, // Actual values for creating custom scopes
    };

    const request: Request = {
      requestType: COGNITO_RESOURCE_SERVER_CREATION_FORM_NAME,
      description: requestMessage,
      approver: approver,
      requester: email,
      content: content,
    };

    const response = await triggerRequest(request, accessToken);
    const requestId = response.data.requestId;

    addNotifications(
      COGNITO_RESOURCE_SERVER_CREATION_FORM_NAME,
      `Cognito resource server creation request sent to '${approver}'.
      You can track the request '${requestId}' in 'Submissions' Tab in Self-Service`,
      'info',
    );

    setIsLoading(false);

    handleReset();
  };

  const submitCheck = () => {
    const canSubmit =
      isLoading ||
      !identifierNameValue ||
      !resourceServerNameValue ||
      !approver ||
      identifierNameValidation ||
      resourceServerNameValidation ||
      scopeNamesValidation.some(truthState => truthState) ||
      scopes.some(
        scope => scope.ScopeName === '' || scope.ScopeDescription === '',
      );

    return canSubmit;
  };

  return (
    <Page themeId="tool">
      <Header
        title={COGNITO_RESOURCE_SERVER_CREATION_FORM_NAME}
        subtitle={COGNITO_RESOURCE_SERVER_CREATION_FORM_DESCRIPTION}
      />
      <Content>
        <Grid container spacing={3} direction="column">
          <Grid item xs={12} md={6}>
            <InfoCard>
              <FormControl variant="standard" fullWidth margin="dense">
                <TextField
                  label="Resource Server Name"
                  variant="outlined"
                  placeholder="Resource Server Name"
                  onChange={onResourceNameChange}
                  error={resourceServerNameValidation}
                  helperText={
                    resourceServerNameValidation
                      ? 'Enter resource name in title case with alphabets only.'
                      : ''
                  }
                  value={resourceServerNameValue}
                  required
                />
                <FormHelperText id="name-helper-text">
                  The resource server name should be in title case, E.g. "My
                  App".
                  <br />
                  The application name must be at least{' '}
                  {APPLICATION_NAME_FOR_APP_CLIENT_MIN_LENGTH} characters long
                  and cannot exceed {APPLICATION_NAME_FOR_APP_CLIENT_MAX_LENGTH}{' '}
                  characters.
                  <br />
                </FormHelperText>
                <br />

                <TextField
                  label="Identifier Name"
                  variant="outlined"
                  placeholder="Identifier Name"
                  value={identifierNameValue}
                  disabled
                  required
                />
                <FormHelperText id="identifier-helper-text">
                  The resource server identifier name will be a lowercased and
                  hypenated version of the application name specified above,
                  <br />
                  E.g. For "my-app".
                  <br />
                </FormHelperText>

                {scopes.map((scope, index) => (
                  <>
                    <br />
                    <div key={index}>
                      <Stack spacing={2} direction="row">
                        <TextField
                          fullWidth
                          label="Name"
                          placeholder="Name"
                          variant="outlined"
                          onChange={event =>
                            handleInputChange(index, event, 'ScopeName')
                          }
                          value={scope.ScopeName}
                          required
                          error={scopeNamesValidation[index]}
                          helperText={
                            scopeNamesValidation[index]
                              ? 'Accepted: Any characters except whitespace (s) or other control characters.'
                              : ''
                          }
                        />
                        <TextField
                          fullWidth
                          label="Description"
                          placeholder="Description"
                          variant="outlined"
                          onChange={event =>
                            handleInputChange(index, event, 'ScopeDescription')
                          }
                          value={scope.ScopeDescription}
                          required
                        />
                        <ButtonGroup variant="contained" color="primary">
                          {scopes.length > 1 && (
                            <Button
                              aria-label="delete"
                              onClick={() => handleRemoveInput(index)}
                            >
                              <DeleteIcon />
                            </Button>
                          )}
                          <Button
                            aria-label="add"
                            onClick={() => handleAddInput()}
                          >
                            <AddIcon />
                          </Button>
                        </ButtonGroup>
                      </Stack>
                    </div>
                  </>
                ))}
                <FormHelperText id="scope-helper-text">
                  Please input the scope name, description pair(s) to be
                  included along with the new resource server.
                </FormHelperText>
                <br />
              </FormControl>
              <BreadBoardAutoComplete
                id="approver"
                options={COGNITO_APPROVER_EMAILS}
                value={approver}
                onChange={(_event: any, newValue: any) => {
                  setApprover(newValue);
                }}
                label="Approver's Email"
              />
              <FormHelperText id="approver-helper-text">
                Please choose an approver who will authorise the update of the
                app client.
              </FormHelperText>
              <br />
              <BreadBoardButtonGroup
                handleSubmit={handleSubmit}
                handleReset={handleReset}
                submitCheck={submitCheck}
                isLoading={isLoading}
              />
            </InfoCard>
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};
