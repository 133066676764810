/* eslint-disable @backstage/no-relative-monorepo-imports */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Content,
  Header,
  InfoCard,
  Link,
  Page,
} from '@backstage/core-components';

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@material-ui/core';

import {
  AWS_USER_ACCESS_FORM_DESCRIPTION,
  AWS_USER_ACCESS_FORM_NAME,
  DEFAULT_APPROVER,
  SSO_PERMISSION_SETS,
  AWS_USER_ACCESS_MODIFICATION_INPUTS_DOC_LINK,
} from '../../../../../packages/app/src/common/constants';
import {
  Account,
  SSOPermissionSetsUser,
  State,
} from '../../../../../packages/app/src/store/types';

import {
  setApplicationAccounts,
  setSSOPermissionSetsUsers,
} from '../../../../../packages/app/src/store/actions';
import { addNotifications } from '../../../../../packages/app/src/store/notifications';
import {
  AWSUserAccessChangeWorkflowRequest,
  PermissionOptions,
  Request,
  fetchAccountData,
  fetchSSOPermissionSetsUsers,
  triggerRequest,
} from '../../internal';
import { AsynchronousAutoComplete, BreadBoardButtonGroup } from '../common';

export const AwsUserAccessForm = () => {
  const dispatch = useDispatch();

  const email = useSelector((state: State) => state.email);
  const accessToken = useSelector((state: State) => state.accessToken);

  useEffect(() => {
    if (accessToken) {
      fetchAccountData(accessToken).then(response => {
        dispatch(setApplicationAccounts(response.applicationAccounts));
      });
      fetchSSOPermissionSetsUsers(accessToken).then(response => {
        dispatch(setSSOPermissionSetsUsers(response));
      });
    }
  }, [dispatch, accessToken]);

  const applicationAccounts = useSelector(
    (state: State) => state.applicationAccounts,
  );
  const ssoPermissionSetsUsers = useSelector(
    (state: State) => state.ssoPermissionSetsUsers,
  );

  const [permissionOptions, setPermissionOptions] = useState<
    PermissionOptions[]
  >([{ name: 'None', value: 'None', description: 'None' }]);
  const [selectedPermission, setSelectedPermission] =
    useState<PermissionOptions>({ name: '', value: '', description: '' });
  const [changeTypeValue, setChangeTypeValue] = useState<string>('assign');
  const [selectedAccountDetail, setSelectedAccountDetail] = useState<Account>({
    accountEmail: '',
    accountId: '',
    accountName: '',
    accountOwner: '',
    accountType: '',
    accountState: '',
    accountStage: '',
    requestId: '',
  });
  const [selectedSSOPermissionSetUser, setSelectedSSOPermissionSetUser] =
    useState<SSOPermissionSetsUser>({
      emailId: '',
      permissionSets: {},
    });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [disablePermissionSets, setDisablePermissionSets] =
    useState<boolean>(true);
  const [approver, setApprover] = useState<string>('');

  const onChangeTypeValueChange = (event: any) =>
    setChangeTypeValue(event.target.value);

  useEffect(() => {
    if (
      JSON.stringify(selectedSSOPermissionSetUser) !==
        JSON.stringify({
          emailId: '',
          permissionSets: {},
        }) &&
      JSON.stringify(selectedAccountDetail) !==
        JSON.stringify({
          email: '',
          id: '',
          name: '',
          owner: '',
          accountType: '',
          accountState: '',
        })
    ) {
      const permissions =
        selectedSSOPermissionSetUser.permissionSets[
          `${selectedAccountDetail.accountName}`
        ] ?? [];

      const permissionsAvailable = SSO_PERMISSION_SETS.filter(item =>
        permissions.includes(item.value),
      );
      const permissionsNotAvailable = SSO_PERMISSION_SETS.filter(item1 => {
        return !permissionsAvailable.some(item2 => {
          return item1 === item2;
        });
      });

      if (changeTypeValue === 'assign') {
        setPermissionOptions(permissionsNotAvailable);
        if (permissionsNotAvailable.length === 0) {
          setPermissionOptions([
            { name: 'None', value: 'None', description: 'None' },
          ]);
        }
      } else if (changeTypeValue === 'unassign') {
        setPermissionOptions(permissionsAvailable);
        if (permissionsAvailable.length === 0) {
          setPermissionOptions([
            { name: 'None', value: 'None', description: 'None' },
          ]);
        }
      }
      setDisablePermissionSets(false);
    } else {
      setDisablePermissionSets(true);
      setPermissionOptions([
        { name: 'None', value: 'None', description: 'None' },
      ]);
    }
  }, [changeTypeValue, selectedAccountDetail, selectedSSOPermissionSetUser]);

  const handleReset = () => {
    setIsLoading(false);
    setChangeTypeValue('assign');
    setSelectedPermission({ name: '', value: '', description: '' });
    setSelectedAccountDetail({
      accountEmail: '',
      accountId: '',
      accountName: '',
      accountOwner: '',
      accountType: '',
      accountState: '',
      accountStage: '',
      requestId: '',
    });
    setSelectedSSOPermissionSetUser({
      emailId: '',
      permissionSets: {},
    });
  };

  useEffect(() => {
    if (
      selectedPermission.name === 'Developer' ||
      selectedPermission.name === 'Read Only'
    ) {
      setApprover(selectedAccountDetail.accountOwner);
    } else {
      setApprover(DEFAULT_APPROVER);
    }
  }, [selectedAccountDetail, selectedPermission]);

  const handleSubmit = async () => {
    setIsLoading(true);

    const requestMessage = `Request: ${changeTypeValue.toUpperCase()} ${
      selectedPermission.name
    } Permission<br />
    Requester: ${email}<br />
    AWS Account Name: ${selectedAccountDetail.accountName}<br />
    Assignee: ${selectedSSOPermissionSetUser.emailId}<br />
    Permission Type: ${selectedPermission.value}<br />
    Description: ${selectedPermission.name} - ${
      selectedPermission.description
    }`;

    const requestContent: AWSUserAccessChangeWorkflowRequest = {
      requester: email,
      permission: selectedPermission.value,
      userEmails: selectedSSOPermissionSetUser.emailId,
      changeType: changeTypeValue,
      accountId: selectedAccountDetail.accountId,
    };

    const request: Request = {
      requestType: AWS_USER_ACCESS_FORM_NAME,
      description: requestMessage,
      approver: approver,
      requester: email,
      content: requestContent,
    };

    const response = await triggerRequest(request, accessToken);
    const requestId = response.data.requestId;

    addNotifications(
      AWS_USER_ACCESS_FORM_NAME,
      `Request sent for approval to approver: '${approver.toLowerCase()}'.
      You can track the request '${requestId}' in 'Submissions' Tab in Self-Service`,
      'info',
    );

    setIsLoading(false);

    handleReset();
  };

  const submitCheck = () => {
    const canSubmit =
      isLoading ||
      disablePermissionSets ||
      JSON.stringify(selectedPermission) ===
        JSON.stringify({ name: '', value: '' });
    return canSubmit;
  };

  return (
    <Page themeId="tool">
      <Header
        title={AWS_USER_ACCESS_FORM_NAME}
        subtitle={AWS_USER_ACCESS_FORM_DESCRIPTION}
      />
      <Content>
        <Grid container xs={12} md={6} spacing={3} direction="column">
          <Grid item>
            <InfoCard>
              <FormControl variant="standard" fullWidth margin="dense">
                <FormLabel id="change-type-radio-button-group-label">
                  Change Type
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="change-type-radio-button-group-label"
                  name="change-type-radio-buttons-group"
                  onChange={onChangeTypeValueChange}
                  value={changeTypeValue}
                >
                  <FormControlLabel
                    value="assign"
                    control={<Radio />}
                    label="Assign"
                  />
                  <FormControlLabel
                    value="unassign"
                    control={<Radio />}
                    label="Unassign"
                  />
                </RadioGroup>
                <br />

                <AsynchronousAutoComplete
                  value={selectedSSOPermissionSetUser}
                  id="sso-permission-set-users-box"
                  options={ssoPermissionSetsUsers}
                  getOptionLabel={option => option.emailId}
                  groupBy={option => option.emailId[0]}
                  label="Email Address"
                  onChange={(_event: any, newValue: any) => {
                    setSelectedSSOPermissionSetUser(newValue);
                  }}
                />
                <FormHelperText id="email-id-helper-text">
                  Choose a user from the list provided above
                  <br />
                  Note : Only users that are part of the AWS SSO Group will
                  display above. If a user is not listed, please see the
                  documentation{' '}
                  <Link to={AWS_USER_ACCESS_MODIFICATION_INPUTS_DOC_LINK}>
                    here{' '}
                  </Link>
                </FormHelperText>
                <br />

                <AsynchronousAutoComplete
                  value={selectedAccountDetail}
                  options={applicationAccounts}
                  getOptionLabel={option => option.accountName}
                  groupBy={option => option.accountName[0]}
                  label="Account Name"
                  id="account-details"
                  onChange={(_event: any, newValue: any) => {
                    setSelectedAccountDetail(newValue);
                  }}
                />
                <FormHelperText id="account-name-helper-text">
                  Select the account to update the permissions on
                </FormHelperText>
                <br />

                <AsynchronousAutoComplete
                  id="sso-permission-sets"
                  options={permissionOptions}
                  onChange={(_event: any, newValue: any) => {
                    setSelectedPermission(newValue);
                  }}
                  getOptionLabel={option => option.name}
                  label={
                    changeTypeValue === 'assign'
                      ? 'Permission to be granted'
                      : 'Permission to be removed'
                  }
                  value={selectedPermission}
                  isTextFieldDisable={disablePermissionSets}
                  tooltipText={
                    disablePermissionSets
                      ? "Please select user's email and account name to get list of permission sets."
                      : undefined
                  }
                />
                <FormHelperText id="sso-permission-sets-helper-text">
                  {changeTypeValue === 'assign'
                    ? 'Select the desired permission to be granted'
                    : 'Select the desired permission to be removed'}
                  <br />
                  {SSO_PERMISSION_SETS[0].name} -{' '}
                  {SSO_PERMISSION_SETS[0].description}
                  <br />
                  {SSO_PERMISSION_SETS[1].name} -{' '}
                  {SSO_PERMISSION_SETS[1].description}
                  <br />
                  {SSO_PERMISSION_SETS[2].name} -{' '}
                  {SSO_PERMISSION_SETS[2].description}
                </FormHelperText>
              </FormControl>
              <br />

              <BreadBoardButtonGroup
                handleSubmit={handleSubmit}
                handleReset={handleReset}
                submitCheck={submitCheck}
                isLoading={isLoading}
              />
            </InfoCard>
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};
