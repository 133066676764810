/* eslint-disable no-console */
/* eslint-disable @backstage/no-relative-monorepo-imports */
import axios, { AxiosError } from 'axios';
import {
  BACKEND_URL,
  RESOURCE_GROUPS_APIS_ENDPOINT,
  RESOURCE_GROUPS_LIST_SERVER_ENDPOINT,
  COGNITO_API_ENDPOINT,
  COGNITO_DESCRIBE_APP_CLIENT_ENDPOINT,
  COGNITO_LIST_RESOURCE_SERVER_ENDPOINT,
  COGNITO_LIST_APP_CLIENTS_ENDPOINT,
  ACCOUNTS_API_ENDPOINT,
  GITHUB_REPOS_API_ENDPOINT,
  USERS_API_ENDPOINT,
} from '../../../../packages/app/src/common/constants';
import {
  AccountData,
  SSOPermissionSetsUser,
} from '../../../../packages/app/src/store/types';
import { Scope, CognitoAppClientDetails } from './types';

export const fetchAccountData = async (
  accessToken: string,
): Promise<AccountData> => {
  const response = await getData(
    `${BACKEND_URL}/${ACCOUNTS_API_ENDPOINT}`,
    accessToken,
  );

  if (response.status === 200) {
    return response.data;
  }

  return {
    applicationAccounts: [],
    developerAccounts: [],
  };
};

export const fetchSSOPermissionSetsUsers = async (
  accessToken: string,
): Promise<SSOPermissionSetsUser[]> => {
  const response = await getData(
    `${BACKEND_URL}/${USERS_API_ENDPOINT}`,
    accessToken,
  );

  if (response.status === 200) {
    return response.data;
  }
  return [];
};

export const fetchGithubRepos = async (
  accessToken: string,
): Promise<string[]> => {
  const response = await getData(
    `${BACKEND_URL}/${GITHUB_REPOS_API_ENDPOINT}`,
    accessToken,
  );

  if (response.status === 200) {
    return response.data;
  }
  return [];
};

export const fetchGithubReposByOrg = async (
  accessToken: string,
  orgName: string,
): Promise<string[]> => {
  const response = await getData(
    `${BACKEND_URL}/${GITHUB_REPOS_API_ENDPOINT}/${orgName}`,
    accessToken,
  );

  if (response.status === 200) {
    return response.data;
  }
  return [];
};

export const fetchCustomScopesList = async (
  accessToken: string,
): Promise<string[]> => {
  const response = await getData(
    `${BACKEND_URL}/${COGNITO_API_ENDPOINT}/${COGNITO_LIST_RESOURCE_SERVER_ENDPOINT}`,
    accessToken,
  );

  if (response.status === 200) {
    return response.data.customScopesValue.map((scope: any) =>
      scope.replace(':', '/'),
    );
  }
  return [];
};

export const fetchCognitoClientDetails = async (
  clientId: string,
  accessToken: string,
): Promise<CognitoAppClientDetails | null> => {
  try {
    const data = {
      clientId: clientId,
    };
    const response = await axios.post(
      `${BACKEND_URL}/${COGNITO_API_ENDPOINT}/${COGNITO_DESCRIBE_APP_CLIENT_ENDPOINT}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    if (response.status === 200) {
      const clientData: CognitoAppClientDetails = response.data.UserPoolClient;
      return {
        ClientName: clientData.ClientName,
        AllowedOAuthScopes: clientData.AllowedOAuthScopes,
        CallbackURLs: clientData.CallbackURLs,
        LogoutURLs: clientData.LogoutURLs,
      };
    }
    return null;
  } catch (error) {
    console.error('Error fetching client details:', error);
    throw error;
  }
};

export const fetchCognitoUserPoolClients = async (
  accessToken: string,
): Promise<{ [key: string]: string }> => {
  try {
    const response = await axios.get(
      `${BACKEND_URL}/${COGNITO_API_ENDPOINT}/${COGNITO_LIST_APP_CLIENTS_ENDPOINT}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    if (response.status === 200) {
      const userPoolClients = response.data.userPoolClients;
      const clientDict: { [key: string]: string } = {};

      Object.entries(userPoolClients).forEach(([clientId, clientName]) => {
        clientDict[clientId] = `${clientName} (ID: ${clientId})`;
      });

      return clientDict;
    }
  } catch (error) {
    if (error instanceof AxiosError) {
      console.error('Error:', error.response?.data || error.message);
    }
  }
  return {};
};

export const fetchGroupValuesList = async (
  accessToken: string,
): Promise<string[]> => {
  const response = await getData(
    `${BACKEND_URL}/${RESOURCE_GROUPS_APIS_ENDPOINT}/${RESOURCE_GROUPS_LIST_SERVER_ENDPOINT}`,
    accessToken,
  );

  if (response.status === 200) {
    return response.data.groupValues;
  }

  return [];
};

export async function getData(url: string, accessToken: string) {
  try {
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json', // Set the appropriate content-type for your request
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response;
  } catch (error) {
    if (error instanceof AxiosError) {
      console.error('Error:', error.response?.data || error.message);
    }
    throw error;
  }
}

export async function postData(url: string, data: any, accessToken: string) {
  try {
    const response = await axios.post(url, data, {
      headers: {
        'Content-Type': 'application/json', // Set the appropriate content-type for your request
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response; // Return the parsed response data
  } catch (error) {
    // Handle any errors that occurred during the fetch request
    if (error instanceof AxiosError) {
      console.error('Error:', error.response?.data || error.message);
    }
    throw error; // Rethrow the error to let the calling code handle it
  }
}

export async function putData(url: string, data: any, accessToken: string) {
  try {
    const response = await axios.put(url, data, {
      headers: {
        'Content-Type': 'application/json', // Set the appropriate content-type for your request
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response; // Return the parsed response data
  } catch (error) {
    // Handle any errors that occurred during the fetch request
    if (error instanceof AxiosError) {
      console.error('Error:', error.response?.data || error.message);
    }
    throw error; // Rethrow the error to let the calling code handle it
  }
}

export const formatScopes = (
  identifierName: string,
  scopes: Scope[],
): string => {
  return scopes
    .map(scope => {
      return `- ${identifierName}/${scope.ScopeName}: ${scope.ScopeDescription}`;
    })
    .join('<br/>');
};

export const formatStringArray = (items: string[]): string => {
  return items
    .map(item => {
      return `- ${item}`;
    })
    .join('<br/>');
};
